import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';

// Auth Config taken from:
// https://timdeschryver.dev/blog/configuring-angular-auth-oidc-client-with-the-new-functional-apis

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes
        ),
        provideHttpClient(
            withInterceptors([
                authInterceptor()
            ])
        ),
        provideAuth({
            config: environment.authConfig,
        }),
    ]
};
