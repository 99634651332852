import { Routes } from '@angular/router';

import { autoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { NotFoundComponent } from './warn/not-found.component';
import { ForbiddenComponent } from './warn/forbidden.component';
import { UnauthorizedComponent } from './warn/unauthorized.component';
import { LoggedOutComponent } from './warn/logged-out.component';
import { CallbackComponent } from './callback/callback.component';

export const routes: Routes = [
    { path: 'callback', component: CallbackComponent },
    {
        path: 'warn',
        loadComponent: () => import('./warn/warn.component').then(c => c.WarnComponent),
        children: [
            { path: '404', component: NotFoundComponent },
            { path: 'forbidden', component: ForbiddenComponent },
            { path: 'unauthorized', component: UnauthorizedComponent },
            { path: 'logout', component: LoggedOutComponent }
        ]
    },
    {
        path: '', 
        loadChildren: () => import('./home/home.routes').then(m => m.routes),
        canMatch: [autoLoginPartialRoutesGuard]
    },
    {
        path: 'print', // TODO: Decide if this should be print or export
        loadChildren: () => import('./print/print.routes').then(m => m.routes),
        canMatch: [autoLoginPartialRoutesGuard]
    },
    { path: '**', pathMatch: 'full', redirectTo: '/warn/404' }
];
