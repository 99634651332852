import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LogLevel, OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styles: '',
})
export class AppComponent implements OnInit, OnDestroy {
    private _destorying$ = new Subject<void>();

    title = 'OpenFLIS';

    constructor(
        private oidcSecurityService: OidcSecurityService,
    ) { }
    
    
    ngOnInit(): void {
        this.oidcSecurityService
            .checkAuth()
            .pipe(takeUntil(this._destorying$))
            .subscribe(({ isAuthenticated, userData, accessToken }) => {
                if (environment.authConfig.logLevel === LogLevel.Debug) {
                    console.log('app authenticated', isAuthenticated);
                    console.log(`Current access token is '${accessToken}'`);
                    console.log('Current user data is', userData);
                }
            });
    }

    ngOnDestroy(): void {
        this._destorying$.next();
        this._destorying$.complete();
    }
}
