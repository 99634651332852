import { Component } from '@angular/core';

@Component({
  selector: 'app-loggedout',
  standalone: true,
  imports: [],
  templateUrl: './logged-out.component.html',
  styles: ``
})
export class LoggedOutComponent {

}
