import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';


// This component is used to handle the callback from the identity provider.
@Component({
  selector: 'app-callback',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './callback.component.html',
  styles: ``
})
export class CallbackComponent {

}
